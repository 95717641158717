import React from 'react';
import './index.css';



const Brand = () => {

    return (
        
       
        <div className="brand">
        
            <h1 className="brandName ">
                <p id="P">P</p><p id="e">e</p><p id="c">c</p><p id="h">h</p><p id="ee">e</p><p id="point">.</p>
                 <p id="m">m</p><p id="eee">e</p><p id="l">l</p><p id="b">b</p><p id="a">a</p><p id="()">()</p>   
             </h1>
        </div>
        
    )
}
export default Brand;