import React from 'react';
import {Link} from 'react-router-dom';
import './index.css';



const Blogenglish = () => {

    return (
        
       
        <div className="">
        <Link to="jp"><div>日本語</div></Link>
          
        </div>
        
    )
}
export default Blogenglish;