import React from 'react';
import {Link} from 'react-router-dom';
import './index.css';




const Blogjapan = () => {

    return (
        
       
        <div className="">
        <Link to="en"><div>English</div></Link>
          
        </div>
        
    )
}
export default Blogjapan;