import React from 'react';
import './index.css';



const Brandjp = () => {

    return (
        
       
        <div className="brand">
        
            <h1 className="brandName ">
                <p id="ee">ピ</p><p id="point">ー</p>
                 <p id="m">チ</p><p id="eee">.</p><p id="l">メ</p><p id="b">ル</p><p id="a">バ</p><p id="()">()</p>   
             </h1>
        </div>
    )
}
export default Brandjp;